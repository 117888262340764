import React, { LegacyRef, useEffect, useMemo, useRef, useState } from 'react';

import {
  Cog6ToothIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/24/outline';
import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';

import { Button } from 'components/common/Button/Button';
import { Modal } from 'components/common/Modal/Modal';
import { Table } from 'components/common/Table/Table';
import { Typography } from 'components/common/Typography/Typography';
import { CheckThirdPartyCookie } from 'components/general/CheckThirdPartyCookie/CheckThirdPartyCookie';
import {
  diagnosisMen,
  diagnosisWomen,
} from 'components/prescriptions/hormones/Diagnosis/diagnosisHelper';
import { newCropDataSchema } from 'constants/validation-schema';
import { OTCItem, Prescription } from 'services/patient/types';
import { PatientPrescriptionService } from 'services/prescription';
import { LicenseNumber } from 'types/common';
import { Option } from 'types/inputTypes';
import { AddressType } from 'types/onboard';
import { AllergyType, Gender } from 'types/patient';
import { HormoneNames } from 'types/prescriptionTypes';
import { SupervisingDoctorDetailsTypeForm } from 'types/provider';

class NewCropError extends Error {
  details: Record<string, unknown>;
  constructor(message: string, details: Record<string, unknown>) {
    super(message, details);
    this.name = 'NewCrop Validation Error';
    this.details = details;
  }
}

export type PatientDiagnosis = {
  id?: string;
  code?: string;
  description?: string;
  type?: string;
};

export type NewCropFrameProps = {
  showButton: boolean;
  isDataLoaded?: boolean;
  onCancel: () => void;
  open?: boolean;
  data?: {
    practice: {
      id: string;
      name: string;
      address: AddressType;
      phoneNumber: string;
      faxNumber: string;
      location: {
        id: string;
        name: string;
        address: AddressType;
        phoneNumber: string;
        faxNumber: string;
      };
    };
    provider: {
      id: string;
      firstName: string;
      lastName: string;
      dea: string;
      deaState: string;
      baseDea: string;
      npi: string;
      licenseState: string;
      licenseNumber: string;
      phone: string;
      suffix: string;
      requireSupervisingProvider: boolean | null;
      supervisingProvider?: SupervisingDoctorDetailsTypeForm & { id: string };
    };
    patient: {
      id: string;
      firstName: string;
      lastName: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      address?: AddressType;
      email: string;
      phoneNumber: string;
      DOB: string;
      gender: Gender;
      allergies?: AllergyType[];
      otherMedications?: Option[] | undefined;
      SSNNumber?: string;
      drivingLicense?: LicenseNumber;
      diagnosis?: { hormone?: PatientDiagnosis; thyroid?: PatientDiagnosis };
      newPatient?: boolean;
    };
    prescription?: (Prescription & { orderItemId: string })[];
    otcItems?: { [x: string]: OTCItem[] };
    pharmacyCode?: string;
    orderCreatedDate?: string;
    orderId: string;
  };
};
const dtdUnits: { [s: string]: string } = {
  ml: 'ML',
  capsules: 'AV',
  tablets: 'U2',
  'usp units': 'EA',
};

const dosageFormName: { [x: string]: string } = {
  'Bi-Est': 'Topical',
  Progesterone: 'Topical',
  'Oral Micronized Progesterone': 'Capsule',
  'Testosterone/DHEA': 'Topical',
  Testosterone: 'Topical',
  DHEA: 'Topical',
  'DHEA oral': 'Capsule',
  'Levothyroxine (T4)': 'Tablet',
  'Liothyronine Extended Release (LAT3)': 'Capsule',
  'T4/LAT3': 'Capsule',
  Sildenafil: 'Tablet',
  Tadalafil: 'Tablet',
  Danazol: 'Capsule',
  'Testosterone Cyp Inj 200mg/ml': 'Injection',
  'Pregnyl (injectable)(HCG)': 'Injection',
};

export function NewCropFrame({
  isDataLoaded,
  open,
  data,
  showButton,
  onCancel,
}: NewCropFrameProps) {
  const [showNewCropFrame, setShowNewCropFrame] = useState(open || false);
  const [instructionModal, setInstructionsModal] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [isCookieEnable, setIsCookieEnable] = useState<boolean | null>(null);

  const formSubmitRef = useRef<HTMLButtonElement>();

  const frameRef = useRef<HTMLIFrameElement>();

  const saveGeneratedXMLMutation = useMutation(
    PatientPrescriptionService.saveGeneratedXML,
    {
      onSuccess() {
        // console.log(data, '******');
      },
    }
  );

  useEffect(() => {
    if (!isCookieEnable) return;
    if (showNewCropFrame) {
      setTimeout(() => {
        if (!validationError) {
          formSubmitRef.current?.click();
          setInstructionsModal(true);
        }
      }, 1000);
    }
  }, [showNewCropFrame, formSubmitRef, validationError, isCookieEnable]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setShowNewCropFrame(true);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (!isDataLoaded) return;

    newCropDataSchema.validate(data, { abortEarly: false }).catch((err) => {
      setErrors([...err.errors]);
      const sentryValidationError = new NewCropError(
        `Validation failed for ${data?.provider.firstName} ${data?.provider.lastName} provider `,
        {}
      );
      Sentry.captureException(sentryValidationError, {
        extra: {
          type: 'Data',
          value: {
            errors: [...err.errors],
            patientId: data?.patient?.id,
            providerId: data?.provider?.id,
            practiceId: data?.practice?.id,
          },
        },
      });
      setValidationError(true);
    });
  }, [isDataLoaded, data]);

  const credentials = {
    partnerName: process.env?.REACT_APP_NEWCROP_PARTNER_NAME,
    name: process.env?.REACT_APP_NEWCROP_USERNAME,
    password: process.env?.REACT_APP_NEWCROP_PASSWORD,
    productName: process.env?.REACT_APP_NEWCROP_PRODUCT_NAME,
    productVersion: process.env?.REACT_APP_NEWCROP_PRODUCT_VERSION,
  };

  const pharmacyIdentifier = data?.pharmacyCode || '4431780';

  const alternativeMedicationName: Partial<Record<string, HormoneNames>> = {
    // These are the medication which have a different name on backend.
    'Testosterone Cyp Inj 200mg/ml': HormoneNames.TestosteroneCyp,
  };

  // const getCompoundBase = (medicationName: HormoneNames): string => {
  //   let compoundBase;
  //   if (data?.patient.gender === Gender.Female) {
  //     compoundBase = Object.values<{
  //       displayName: string;
  //       compoundName: string;
  //     }>(femaleHormoneRxTypes).find(
  //       (rx) =>
  //         rx.displayName ===
  //         (alternativeMedicationName[medicationName] || medicationName)
  //     )?.compoundName;
  //   } else if (data?.patient.gender === Gender.Male) {
  //     compoundBase = Object.values<{
  //       displayName: string;
  //       compoundName: string;
  //     }>(maleHormoneRxTypes).find(
  //       (rx) =>
  //         rx.displayName ===
  //         (alternativeMedicationName[medicationName] || medicationName)
  //     )?.compoundName;
  //   }
  //   if (compoundBase) return compoundBase;

  //   const sentryMedicationNotFoundError = new NewCropError(
  //     `Medication not found: ${medicationName}`,
  //     {}
  //   );
  //   Sentry.captureException(sentryMedicationNotFoundError, {
  //     extra: {
  //       type: 'Data',
  //       value: {
  //         errors: ['Medication not found'],
  //         patientId: data?.patient?.id,
  //         providerId: data?.provider?.id,
  //         practiceId: data?.practice?.id,
  //       },
  //     },
  //   });
  //   throw sentryMedicationNotFoundError;
  // };

  const getDrugSchedule = (medicationName: HormoneNames) => {
    if (
      [
        HormoneNames.TESTO_PER_DHEA,
        HormoneNames.TESTOSTERONE_MEN,
        HormoneNames.TESTOSTERONE_WOMEN,
        HormoneNames.TestosteroneCyp,
      ].includes(alternativeMedicationName[medicationName] || medicationName)
    ) {
      return '3';
    }

    return 'None';
  };

  const mailBodyText = `Hey%20Team,%0D%0A%0D%0AI%20encountered%20some%20error(s)%20while%20submitting%20an%20order%20to%20New-Crop.%20The%20following%20is%20the%20list%20of%20error(s):%0D%0A%0D%0A${errors?.reduce(
    (acc, err, index) => `${acc}${index + 1}.%20${err}%0D%0A`,
    ''
  )}%0D%0AThese%20issues%20have%20hindered%20the%20successful%20submission%20of%20the%20order,%20and%20I%20kindly%20request%20your%20prompt%20attention%20to%20rectify%20these%20errors.%0D%0A%0D%0AProvider%20ID:%20${
    data?.provider?.id
  }%0D%0APatient%20ID:%20${data?.patient?.id}%0D%0A%0D%0AThanks,%0D%0A${
    data?.provider?.firstName
  }%20${data?.provider.lastName}`;

  // const patientLicenseLabel = data?.patient.drivingLicense
  //   ? ` DL: ${data.patient.drivingLicense.licenseNumber} ${data.patient.drivingLicense.state.name} ${data.patient.drivingLicense.expDate}.`
  //   : 'DL: NA.';
  // const patientSSNLabel = ` SSN: ${data?.patient.SSNNumber || 'NA'}.`;
  // const providerCredentialsLabel = `Provider Credentials: ${
  //   data?.provider.suffix || '-'
  // }.`;

  // const otherMedicationsLabel = data?.patient?.otherMedications?.[0]?.value
  //   ? ''
  //   : 'Takes No Medications.';

  const patientAllergies = data?.patient?.allergies
    ?.map(
      (allergy) =>
        `<PatientAllergies>
      <allergyID>${allergy?.id}</allergyID>
      <allergyTypeID>RXNORM</allergyTypeID>
      <allergyName>${allergy.title}</allergyName>
    </PatientAllergies>
    `
    )
    .toString()
    .replaceAll(',', '  ');

  const patientHormoneDiagnosis = useMemo(
    () => `<patientDiagnosis> 
        <diagnosisID>${data?.patient.diagnosis?.hormone?.code}</diagnosisID>
        <diagnosisType>ICD10</diagnosisType>
        <diagnosisName>${data?.patient.diagnosis?.hormone?.description}</diagnosisName>
      </patientDiagnosis>`,
    [data]
  );

  const patientThyroidDiagnosis = useMemo(
    () => `<patientDiagnosis> 
        <diagnosisID>${data?.patient.diagnosis?.thyroid?.code}</diagnosisID>
        <diagnosisType>ICD10</diagnosisType>
        <diagnosisName>${data?.patient.diagnosis?.thyroid?.description}</diagnosisName>
      </patientDiagnosis>`,
    [data]
  );

  function getDiagnosisCode(prescriptionName: HormoneNames) {
    if (data?.patient.gender === 'F') {
      if (diagnosisWomen.hormone.includes(prescriptionName)) {
        return patientHormoneDiagnosis;
      }
      if (diagnosisWomen.thyroid.includes(prescriptionName)) {
        return patientThyroidDiagnosis;
      }
    }
    if (data?.patient.gender === 'M') {
      if (
        diagnosisMen.hormone.includes(
          alternativeMedicationName[prescriptionName] || prescriptionName
        )
      ) {
        return patientHormoneDiagnosis;
      }
      if (diagnosisMen.thyroid.includes(prescriptionName)) {
        return patientThyroidDiagnosis;
      }
    }

    return '';
  }

  const getPrescriptionName = (p: Prescription) => {
    if (['Testosterone/DHEA'].includes(p.prescriptionToMedication.name)) {
      const strengthSplitArray = p?.strength?.value.toString().split('/');

      return `Testosterone ${strengthSplitArray[0]}mg/ml DHEA ${
        strengthSplitArray[1]
      }mg/ml ${dosageFormName[p.prescriptionToMedication.name]}`;
    }

    return `${p.prescriptionToMedication.name} ${
      p?.ratio ? `${`${p?.ratio?.e3}:${p?.ratio?.e2}`} ` : ''
    } ${dosageFormName[p.prescriptionToMedication.name]} ${
      p?.strength?.value
    } ${p?.strength?.unit}`;
  };

  const prescribedPregnylMedication = data?.prescription?.find(
    (prescription) =>
      (alternativeMedicationName[prescription.prescriptionToMedication.name] ||
        prescription.prescriptionToMedication.name) === HormoneNames.Pregnyl
  );

  const prescribedTestosteroneCypMedication = data?.prescription?.find(
    (prescription) =>
      (alternativeMedicationName[prescription.prescriptionToMedication.name] ||
        prescription.prescriptionToMedication.name) ===
      HormoneNames.TestosteroneCyp
  );

  const alternativeProviderIds = {
    // deHF7k6Q: 'ALTdeHF7k6Q',
  };

  const providerMiddleNameMap = {
    fowV9y7E: 'Kent', // Steven Jones
    V3Cydvp2: 'F', // Philip Troiano
  };

  const providerMiddleName =
    providerMiddleNameMap[
      data?.provider.id as keyof typeof providerMiddleNameMap
    ];

  const getLocationDea = () => {
    if (data?.provider.baseDea === data?.provider.dea || !data?.provider.dea) {
      return '  ';
    }

    return `<locationDea>${data?.provider.dea}</locationDea>`;
  };

  const getLicensePrescriber = () => {
    if (
      data?.provider.requireSupervisingProvider === true &&
      data.provider.supervisingProvider
    ) {
      return `<SupervisingDoctor ID="${data.provider.supervisingProvider?.id}">
                <LicensedPrescriberName>
                    <last>${data.provider.supervisingProvider?.lastName}</last>
                    <first>${
                      data.provider.supervisingProvider?.firstName
                    }</first>
                    ${
                      data.provider.supervisingProvider?.middleName
                        ? '<middle>{data.provider.supervisingProvider?.middleName}</middle>'
                        : ''
                    }
                    <suffix>${
                      data.provider.supervisingProvider?.suffix
                    }</suffix>
                </LicensedPrescriberName>
                <dea>${data.provider.supervisingProvider?.dea}</dea>
                <licenseState>${
                  data.provider.supervisingProvider?.licenseState
                }</licenseState>
                <licenseNumber>${
                  data.provider.supervisingProvider?.licenseNumber
                }</licenseNumber>
                  ${
                    data.provider?.supervisingProvider?.npi
                      ? `<npi>${data.provider?.supervisingProvider?.npi}</npi>`
                      : ''
                  } 
              </SupervisingDoctor>
              <MidlevelPrescriber ID="${
                alternativeProviderIds[
                  data?.provider.id as keyof typeof alternativeProviderIds
                ] || data?.provider.id
              }">
                <LicensedPrescriberName>
                    <last>${data?.provider.lastName}</last>
                    <first>${data?.provider.firstName}</first>
                    ${
                      providerMiddleName
                        ? `<middle>${providerMiddleName}</middle>`
                        : ''
                    }
                    <suffix>${data?.provider?.suffix}</suffix>
                </LicensedPrescriberName>
                <dea>${data?.provider?.baseDea}</dea>
                <licenseState>${data?.provider.licenseState}</licenseState>
                <licenseNumber>${data?.provider.licenseNumber}</licenseNumber>
                <npi>${data?.provider.npi}</npi>
                ${getLocationDea()}
              </MidlevelPrescriber>`;
    }

    return `<LicensedPrescriber ID="${
      alternativeProviderIds[
        data?.provider.id as keyof typeof alternativeProviderIds
      ] || data?.provider.id
    }">
      <LicensedPrescriberName>
        <last>${data?.provider.lastName}</last>
        <first>${data?.provider.firstName}</first>
        <suffix>${data?.provider?.suffix}</suffix>
      </LicensedPrescriberName>
      <dea>${data?.provider?.baseDea}</dea>
      <licenseState>${data?.provider.licenseState}</licenseState>
      <licenseNumber>${data?.provider.licenseNumber}</licenseNumber>
      <npi>${data?.provider.npi}</npi>
      ${getLocationDea()}
    </LicensedPrescriber>`;
  };

  const xml = `
  <NCScript xmlns="http://secure.newcropaccounts.com/interfaceV7"
    xmlns:NCStandard="http://secure.newcropaccounts.com/interfaceV7:NCStandard"
    xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
    <Credentials>
      <partnerName>${credentials.partnerName}</partnerName>
      <name>${credentials.name}</name>
      <password>${credentials.password}</password>
      <productName>${credentials.productName}</productName>
      <productVersion>${credentials.productVersion}</productVersion>
    </Credentials>

    <UserRole>
     ${
       data?.provider.requireSupervisingProvider === true
         ? `<user>MidlevelPrescriber</user>
      <role>midlevelPrescriber</role>`
         : `<user>LicensedPrescriber</user>
      <role>doctor</role>`
     }
      
    </UserRole>

    <Destination>
      <requestedPage>compose</requestedPage>
    </Destination>

    <Account ID="${data?.practice.id}">
      <accountName>${data?.practice.name}</accountName>
      <siteID>BriteLive</siteID>
      <AccountAddress>
        <address1>${data?.practice?.location?.address?.addressLine1}</address1>
        ${
          data?.practice?.location?.address?.addressLine2
            ? `<address2>${data?.practice?.location?.address?.addressLine2}</address2>`
            : ''
        }
        <city>${data?.practice?.location?.address?.city}</city>
        <state>${data?.practice?.location?.address?.state?.abbreviation}</state>
        <zip>${data?.practice?.location?.address?.zipCode}</zip>
        <country>${data?.practice?.location?.address?.country}</country>
      </AccountAddress>
      <accountPrimaryPhoneNumber>${data?.practice?.location?.phoneNumber.substring(
        2
      )}</accountPrimaryPhoneNumber>
      <accountPrimaryFaxNumber>${
        data?.practice?.location?.faxNumber
      }</accountPrimaryFaxNumber>
    </Account>

    <Location ID="${data?.practice.location.id}">
      <locationName>${data?.practice.location.name}</locationName>
      <LocationAddress>
        <address1>${data?.practice?.location?.address?.addressLine1}</address1>
        ${
          data?.practice?.location?.address?.addressLine2
            ? `<address2>${data?.practice?.location?.address?.addressLine2}</address2>`
            : ''
        } 
        <city>${data?.practice?.location?.address?.city}</city>
        <state>${data?.practice?.location?.address?.state?.abbreviation}</state>
        <zip>${data?.practice?.location?.address?.zipCode}</zip>
        <country>${data?.practice?.location?.address?.country}</country>
      </LocationAddress>
      <primaryPhoneNumber>${data?.practice?.location?.phoneNumber.substring(
        2
      )}</primaryPhoneNumber>
      <primaryFaxNumber>${
        data?.practice?.location?.faxNumber
      }</primaryFaxNumber>
      <pharmacyContactNumber>${data?.practice?.location?.phoneNumber.substring(
        2
      )}</pharmacyContactNumber> 
    </Location>

    ${getLicensePrescriber()}

    

    <Patient ID="${data?.patient.id}">
      <PatientName>
        <last>${data?.patient.lastName}</last>
        <first>${data?.patient.firstName}</first>
      </PatientName>
      <PatientAddress>
        <address1>${data?.patient?.address?.addressLine1}</address1>
        ${
          data?.patient?.address?.addressLine2
            ? `<address2>${data?.patient?.address?.addressLine2}</address2>`
            : ''
        }
        <city>${data?.patient?.address?.city}</city>
        <state>${data?.patient?.address?.state?.abbreviation}</state>
        <zip>${data?.patient?.address?.zipCode}</zip>
        <country>${data?.patient?.address?.country}</country>
      </PatientAddress>
      <PatientContact>
        <cellularTelephone>${data?.patient.phoneNumber.substring(
          2
        )}</cellularTelephone>
        <email>${data?.patient.email}</email>
      </PatientContact>
      <PatientCharacteristics>
        <dob>${data?.patient.DOB}</dob>
        <gender>${data?.patient.gender}</gender>
      </PatientCharacteristics>
      ${
        patientAllergies?.[0]
          ? patientAllergies
          : `<PatientAllergies>
            <allergyID>232</allergyID>
            <allergyTypeID>FDB</allergyTypeID>
            <allergyName>NKDA</allergyName>
          </PatientAllergies>`
      }
      <PatientPharmacies>
        <pharmacyIdentifier>${pharmacyIdentifier}</pharmacyIdentifier>
      </PatientPharmacies>
    </Patient>
  ${
    data?.prescription &&
    data?.prescription
      .map(
        (p) =>
          `
    <OutsidePrescription>
      <externalId>${p?.orderItemId}</externalId>
      <pharmacyIdentifier>${pharmacyIdentifier}</pharmacyIdentifier>
      <dispenseNumber>${p?.dtd?.value}</dispenseNumber>
      <sig>${p?.deliveryMethodAndSig.sig} </sig>
      <refillCount>${p?.refills}</refillCount>
      <pharmacistMessage>Brite, ${
        data?.patient?.newPatient === true ? 'New Patient,' : ''
      } ${p?.loe ?? ''}</pharmacistMessage>
      <drugIdentifier>0</drugIdentifier>
      <drugIdentifierType>Z</drugIdentifierType>
      <prescriptionType>reconcile</prescriptionType>
      <externalOverrideDrug>
        <externalDrugConcept>${getPrescriptionName(p)}</externalDrugConcept>
        <externalDrugSchedule>${getDrugSchedule(
          p?.prescriptionToMedication?.name
        )}</externalDrugSchedule>
       
      </externalOverrideDrug>
      <prescriptionStatus>Pending</prescriptionStatus>
      <dispenseNumberQualifier>${
        dtdUnits?.[p?.dtd?.unit]
      }</dispenseNumberQualifier>
      <daysSupply>${p?.daysSupply}</daysSupply>
      ${getDiagnosisCode(p.prescriptionToMedication.name)}
      ${
        data?.orderCreatedDate
          ? `<effectiveDate>${data?.orderCreatedDate}</effectiveDate>`
          : ''
      }
   </OutsidePrescription>`
      )
      .toString()
      .replaceAll(',', '\n')
  }
  ${
    prescribedTestosteroneCypMedication
      ? `
    <OutsidePrescription>
      <externalId>${
        prescribedTestosteroneCypMedication.orderItemId
      }_TestosteroneCypKitItem</externalId>
      <pharmacyIdentifier>${pharmacyIdentifier}</pharmacyIdentifier>
      <dispenseNumber>1</dispenseNumber>
      <sig>Use 21g needle w 1ml syringe. Draw up prescribed ml of testosterone from 10ml vial. Replace 21g w a 25g. Inject prescribed ml as directed.</sig>
      <refillCount>${prescribedTestosteroneCypMedication?.refills}</refillCount>
      <pharmacistMessage>
        Brite
        ${data?.otcItems?.[prescribedTestosteroneCypMedication.orderItemId]
          ?.map((i) => `${i.name}: ${i.quantity} count `)
          .toString()
          .replaceAll(',', ', ')}
      </pharmacistMessage>
      <drugIdentifier>0</drugIdentifier>
      <drugIdentifierType>Z</drugIdentifierType>
      <prescriptionType>reconcile</prescriptionType>
      <externalOverrideDrug>
        <externalDrugConcept>Testosterone Injection Kit</externalDrugConcept>
        <externalDrugSchedule>None</externalDrugSchedule>
      </externalOverrideDrug>
      <prescriptionStatus>Pending</prescriptionStatus>
      <dispenseNumberQualifier>EA</dispenseNumberQualifier>
    </OutsidePrescription>`
      : ''
  }


   ${
     prescribedPregnylMedication
       ? `
    <OutsidePrescription>
      <externalId>${
        prescribedPregnylMedication.orderItemId
      }_PregnylKitItem</externalId>
      <pharmacyIdentifier>${pharmacyIdentifier}</pharmacyIdentifier>
      <dispenseNumber>1</dispenseNumber>
      <sig>Do not shake vial. Agitate gently until powder is entirely dissolved in solution. Reconstituted solution good for up to 60 days refrigerated</sig>
      <refillCount>${prescribedPregnylMedication?.refills}</refillCount>
      <pharmacistMessage>
        Brite
         ${data?.otcItems?.[prescribedPregnylMedication.orderItemId]
           ?.map((i) => `${i.name}: ${i.quantity} count `)
           .toString()
           .replaceAll(',', '       ')}
      </pharmacistMessage>
      <drugIdentifier>0</drugIdentifier>
      <drugIdentifierType>Z</drugIdentifierType>
      <prescriptionType>reconcile</prescriptionType>
      <externalOverrideDrug>
        <externalDrugConcept>Pregnyl (injectable)(HCG) Kit</externalDrugConcept>
        <externalDrugSchedule>None</externalDrugSchedule>
      </externalOverrideDrug>
      <prescriptionStatus>Pending</prescriptionStatus>
      <dispenseNumberQualifier>EA</dispenseNumberQualifier>
    </OutsidePrescription>`
       : ''
   }
  </NCScript>`;

  useEffect(() => {
    if (showNewCropFrame && data?.orderId && xml) {
      saveGeneratedXMLMutation.mutate({ xml, orderId: data?.orderId ?? '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNewCropFrame, data?.orderId]);

  function showErrorUI() {
    if (isCookieEnable === false) {
      return (
        <div className="flex flex-col  gap-2 bg-white">
          <Typography className="" variant="h2">
            Cookies are disabled
          </Typography>
          <Typography className="" variant="subtitle1">
            Please allow third party cookies from browser setting.
          </Typography>
          <div className="mt-10 flex flex-col gap-2">
            <Typography className="" variant="h4">
              Please follow instructions given below to enable it on your
              particular browser.
            </Typography>
            <div className=" mt-4 flex flex-col gap-2">
              <Typography variant="subtitle1">For Safari:</Typography>
              <Typography variant="subtitle1">
                1. At the top left, click on safari and then Settings.
              </Typography>

              <Typography variant="subtitle1">
                2. Click Privacy Tab and checkbox for Prevent cross-site
                tracking should be unchecked.
              </Typography>
              <Typography variant="subtitle1">
                3. Go to Advanced Tab, Deselect Block all cookies.
              </Typography>
              <Typography variant="subtitle1">4. Reload the page.</Typography>
            </div>
            <div className=" mt-4 flex flex-col gap-2">
              <Typography variant="subtitle1">For Chrome:</Typography>
              <Typography variant="subtitle1">
                1. At the top right, click More{' '}
                <EllipsisVerticalIcon className=" h-[20px] cursor-pointer via-gray-300 text-zinc-700" />{' '}
                and then Settings{'    '}
                <Cog6ToothIcon className="ml-1 h-[20px] cursor-pointer via-gray-300 text-zinc-700" />{' '}
                .
              </Typography>
              <Typography variant="subtitle1">
                2. Click Privacy and security and then Third-party cookies.
              </Typography>
              <Typography variant="subtitle1">
                3. Select an option: Allow third-party cookies.
              </Typography>
              <Typography variant="subtitle1">4. Reload the page.</Typography>
            </div>
            <div className=" mt-4 flex flex-col gap-2">
              <Typography variant="subtitle1">For Firefox:</Typography>
              <Typography variant="subtitle1">
                1. Click on the shield to the left of the address bar.
              </Typography>
              <Typography variant="subtitle1">
                2. Toggle the switch at the top of the panel. This will turn off
                Enhanced Tracking Protection for this site.
              </Typography>

              <Typography variant="subtitle1">3. Reload the page.</Typography>
            </div>
          </div>
        </div>
      );
    }

    if (validationError) {
      return (
        <div className="flex flex-col  gap-4 bg-white">
          <Typography className="self-center" variant="h3">
            Errors encountered
          </Typography>
          <div className="mt-2 flex flex-col gap-2">
            <Table
              rowsData={errors}
              columns={[
                {
                  title: 'Sr. No',
                  render: (rowData, index) => index + 1,
                  classNames: 'text-center',
                },
                {
                  title: 'Issues',
                  render: (rowData) => rowData,
                },
              ]}
            />
            <Typography
              variant="h5"
              className=" rounded bg-error-light px-4 py-2 "
              color="textSecondary">
              Please reach out to our support team at support@brite.live to get
              help.
            </Typography>
          </div>

          <div className="self-center">
            <Button
              onClick={() => {
                window.location.href = `mailto:support@brite.live?subject=Submission%20Error(s)%20Report%20for%20New-Crop%20Order&body=${mailBodyText}`;
              }}>
              Report
            </Button>{' '}
          </div>
        </div>
      );
    }

    return '';
  }

  return (
    <>
      {showButton && (
        <Button onClick={() => setShowNewCropFrame(true)} type="submit">
          Resubmit Prescription(s)
        </Button>
      )}
      <Modal
        className="fixed h-screen w-full"
        closeModal={() => null}
        open={showNewCropFrame}>
        <CheckThirdPartyCookie
          onCookieStatusResolved={(isEnabled) => {
            setIsCookieEnable(isEnabled);
          }}
        />

        <form
          className=""
          target="newCrop_frame"
          action={process.env.REACT_APP_NEWCROP_BASE_URL}
          method="post">
          <input className="hidden" value={xml} name="RxInput" />
          <button
            className="hidden"
            ref={formSubmitRef as LegacyRef<HTMLButtonElement>}
            type="submit">
            Submit
          </button>
        </form>
        <div className={classNames('m-10 block bg-slate-50  ')}>
          {validationError || !isCookieEnable ? (
            showErrorUI()
          ) : (
            <iframe
              ref={frameRef as LegacyRef<HTMLIFrameElement> | undefined}
              className={classNames(
                ' fixed top-2/4 left-2/4 z-40 block h-full w-full -translate-y-1/2 -translate-x-1/2 rounded  bg-slate-50  '
              )}
              title="new crop"
              name="newCrop_frame"
              id="newCrop_frame">
              {' '}
            </iframe>
          )}
          <Modal
            title="Important Instructions"
            className="fixed  w-2/3 "
            closeModal={() => setInstructionsModal(false)}
            open={instructionModal}>
            <div className="flex  flex-col justify-between gap-4 pt-6">
              <div className="flex gap-2 text-lg font-bold text-gray-600">
                1.
                <Typography variant="h4">
                  {' '}
                  Please do not modify, add, or remove any part of the
                  prescriptions on the screens to follow. Any modifications may
                  cause the order to be rejected.
                </Typography>
              </div>
              <div className="flex gap-2 text-lg font-bold text-gray-600">
                2.
                <Typography variant="h4">
                  {' '}
                  If the Patient is not on any active medication, Make sure to
                  check the Takes No Medications checkbox.
                </Typography>{' '}
              </div>
              <div className="flex gap-2 text-lg font-bold text-gray-600">
                3.
                <Typography variant="h4">
                  {' '}
                  Please make sure to select all the medications and then click
                  on the Prescribe button.
                </Typography>{' '}
              </div>
              <div className="flex gap-2 text-lg font-bold text-gray-600">
                4.
                <Typography variant="h4">
                  {' '}
                  Please make sure to transmit the prescription before returning
                  to Brite.
                </Typography>
              </div>
              <div className="flex gap-2 text-lg font-bold text-gray-600">
                5.
                <Typography className="" variant="h4">
                  We&apos;re here to help. Reach out to support@brite.live when
                  you need us. Please share the patient ID and an explanation of
                  the challenge.
                </Typography>
              </div>
              <div className="mt-12 self-end">
                <Button
                  color="secondary"
                  onClick={() => {
                    setInstructionsModal(false);
                  }}>
                  Close
                </Button>
              </div>
            </div>
          </Modal>

          <div className="light fixed left-0 bottom-0 z-50 flex   w-full   justify-between rounded   bg-white p-2 ">
            {validationError || !isCookieEnable ? (
              <div>
                <Button
                  onClick={() => {
                    onCancel();
                    setShowNewCropFrame(false);
                  }}
                  color="secondary">
                  Close
                </Button>
              </div>
            ) : (
              <>
                {' '}
                <div className="flex gap-2">
                  <Button
                    onClick={() => {
                      onCancel();
                      setShowNewCropFrame(false);
                    }}
                    className=" ">
                    Return to Brite
                  </Button>
                  <Typography className="" color="error">
                    Click only after being shown confirmation of prescription(s)
                    submission!
                  </Typography>{' '}
                </div>
                <Button
                  color="secondary"
                  onClick={() => setInstructionsModal(true)}>
                  Instructions
                </Button>
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}
